import axios from 'axios';
import config from '../config';
import authInterceptor from './interceptors/interceptors';

import authNetwork from './auth';
import apiCompanyNetwork from './apiCompany';
import ticketNetwork from './apiTicket';
import insuranceNetwork from './insurances';
import collectionTicketNetwork from './collectionTicket';
import apiDigitalForm from './apiDigitalform';
import TicketService from './ticket-service';
import DependantIncorporationService from './dependant-incorporation-service';
import CompanyService from './company-service';

const appConfig = config();
const httpConfig = {
  baseURL: appConfig.baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  withCredentials: true
};
const privatehttp = axios.create(httpConfig);
const publichttp = axios.create(httpConfig);

const interceptor = authInterceptor(privatehttp);
privatehttp.interceptors.request.use(interceptor.authRequest, (error) =>
  Promise.reject(error)
);
privatehttp.interceptors.response.use(
  (response) => response,
  interceptor.tokenResponse
);

export const ticketService = new TicketService(privatehttp);
export const dependantIncorporationService = new DependantIncorporationService(
  privatehttp
);
export const companyService = new CompanyService(privatehttp);
const bewellAuth = authNetwork(privatehttp);
const companyClient = apiCompanyNetwork(privatehttp);
const privateTicketService = ticketNetwork(privatehttp);
const ticketServicePublic = ticketNetwork(publichttp);
const insuranceService = insuranceNetwork(privatehttp);
const allCollectionTicket = collectionTicketNetwork(privatehttp);
const digitalFormService = apiDigitalForm(privatehttp);

export const { apiBewellAuth } = bewellAuth;
export const { tokenValidation } = bewellAuth;
export const { tokenRefresh } = bewellAuth;
export const { deleteTokens } = bewellAuth;
export const { allUser } = bewellAuth;
export const { authUser } = bewellAuth;
export const { updateUser } = bewellAuth;
export const { userState } = bewellAuth;
export const { authRegister } = bewellAuth;
export const { authLogin } = bewellAuth;
export const { resetMail } = bewellAuth;
export const { resetPassword } = bewellAuth;
export const { CreateResetPassword } = bewellAuth;
export const { deleteCookie } = bewellAuth;
export const { ValidationCookie } = bewellAuth;
export const { getOneUserById } = bewellAuth;
export const { apiCompanyClient } = companyClient;
export const { Company } = companyClient;
export const { companiesByEmployee } = companyClient;
export const { excelEmployeeCompany } = companyClient;
export const { CompanyById } = companyClient;
export const { UserByCompanyId } = companyClient;
export const { CompanyGroup } = companyClient;
export const { CompanyEdit } = companyClient;
export const { CompanyDelete } = companyClient;
export const { CompanyCreate } = companyClient;
export const { CompanyFetchTableData } = companyClient;
export const { companyPoliciesConfiguration } = companyClient;
export const { EditCompany } = companyClient;
export const { deleteCompany } = companyClient;
export const { getCompanyMailbox } = companyClient;
export const { postCompanyMailbox } = companyClient;
export const { putCompanyMailbox } = companyClient;
export const { CompanyNotificationsById } = companyClient;
export const { InsuranceCompanyNotificationsById } = companyClient;
export const { CompanyPutNotificationsById } = companyClient;
export const { InsuranceCompanyPutNotificationsById } = companyClient;
export const { postConfigPolicy } = companyClient;
export const { getPolicyInvoicePayment } = companyClient;
export const { getCutOffs } = companyClient;
export const { getAllConfigPolicy } = companyClient;
export const { putConfigPolicy } = companyClient;
export const { deleteClientExecutiveInsuranceCompany } = companyClient;
export const { getCompanyEmployeeCoverage } = companyClient;
export const { companyGetExecutives } = companyClient;
export const { companyGetExecutive } = companyClient;
export const { createCompanyExecutive } = companyClient;
export const { editExecutive } = companyClient;
export const { holdingfetch } = companyClient;
export const { holdingFetchTableData } = companyClient;
export const { getBajaStockFormat } = companyClient;
export const { uploadExcelToBajaStock } = companyClient;
export const { holding } = companyClient;
export const { holdingEdit } = companyClient;
export const { deleteHolding } = companyClient;
export const { createSubHolding } = companyClient;
export const { editSubHolding } = companyClient;
export const { deleteSubholding } = companyClient;
export const { subholdingFetch } = companyClient;
export const { subholdingFetchTableData } = companyClient;
export const { employeeCoverages } = companyClient;
export const { employeeAsociation } = companyClient;
export const { companyInsuranceCompany } = companyClient;
export const { policityInsuranceCompany } = companyClient;
export const { companyGroupCompany } = companyClient;
export const { policyGroupCompany } = companyClient;
export const { companyGroupPremiumCalc } = companyClient;
export const { configureCompanyGroupPremiumCalc } = companyClient;
export const { employee } = companyClient;
export const { employeeCompanies } = companyClient;
export const { employeeInsurance } = companyClient;
export const { getInsuranceCompanyFormType } = companyClient;
export const { saveInsuranceCompanyFormType } = companyClient;
export const { dataEmployee } = companyClient;
export const { currentEmployeePoliciesAndCoverages } = companyClient;
export const { patchDataEmployee } = companyClient;
export const { saveEmployeeCompanyRent } = companyClient;
export const { employeeCompanyRent } = companyClient;
export const { postEmployee } = companyClient;
export const { groupCompanyEmployee } = companyClient;
export const { insuranceCompanyById } = companyClient;
export const { insuranceCompanyPayrollsById } = companyClient;
export const { insuranceCompanyByHoldingId } = companyClient;
export const { insuranceCompanyBySubholdingId } = companyClient;
export const { insuranceCompanyByCompanyId } = companyClient;
export const { insuranceGetMailbox } = companyClient;
export const { insurancePostMailbox } = companyClient;
export const { insurancePutMailbox } = companyClient;
export const { insuranceGetManual } = companyClient;
export const { insurancePostManual } = companyClient;
export const { insuranceDeleteManual } = companyClient;
export const { insurancePutManual } = companyClient;
export const { getCompanyLogo } = companyClient;
export const { saveCompanyLogo } = companyClient;
export const { getCompanyDigitalFormConsentTexts } = companyClient;
export const { putCompanyDigitalFormConsentTexts } = companyClient;
export const { companyByUserId } = companyClient;
export const { InsuranceCompanyByEmployeeGroupCompany } = companyClient;
export const { policy } = companyClient;
export const { getPolicyCoverages } = companyClient;
export const { postCoverage } = companyClient;
export const { putCoverage } = companyClient;
export const { deleteCoverage } = companyClient;
export const { getCoverage } = companyClient;
export const { coverageUpdate } = companyClient;
export const { coverageToEmployed } = companyClient;
export const { getDependent } = companyClient;
export const { deleteDependent } = companyClient;
export const { dependent } = companyClient;
export const { employeesByCompany } = companyClient;
export const { getPolicies } = companyClient;
export const { getCoverages } = companyClient;
export const { postPolicy } = companyClient;
export const { putPolicy } = companyClient;
export const { getPolicy } = companyClient;
export const { deletePolicy } = companyClient;
export const { postLoadsdown } = companyClient;
export const { updateEmployeeDependent } = companyClient;
export const { deleteEmployeeLoads } = companyClient;
export const { userCompanyClient } = companyClient;
export const { userCompanyClientupdate } = companyClient;
export const { userStateClient } = companyClient;
export const { companyClientRegister } = companyClient;
export const { companyClientLogin } = companyClient;
export const { firstUserClient } = companyClient;
export const { ClientResetEmail } = companyClient;
export const { ClientReset } = companyClient;
export const { companiesStockDeregistration } = companyClient;
export const { patchEmployeeCoverage } = companyClient;
export const { patchEmployeeCoverageRequestDate } = companyClient;
export const { getAllBrokers } = companyClient;
export const { getBrokerByCompany } = companyClient;
export const { getCompanyEmailConfigurations } = companyClient;
export const { patchCompanyEmailConfiguration } = companyClient;
export const { postCompanyEmailConfiguration } = companyClient;
export const { createBrokerToCompany } = companyClient;
export const { deleteBrokerToCompany } = companyClient;
export const { getCurrentEmployeeCoverage } = companyClient;
export const { getEmployeeCoverage } = companyClient;
export const { deleteEmployeeCoverage } = companyClient;
export const { patchConsentText } = privateTicketService;
export const { resendEmail } = privateTicketService;
export const { getConsentText } = privateTicketService;
export const { createStateCase } = privateTicketService;
export const { postFileInsuranceCompany } = privateTicketService;
export const { typeFormsInsuranceCompany } = privateTicketService;
export const { customIdentity } = privateTicketService;
export const { fileTicketHistory } = privateTicketService;
export const { createCustomerIdentity } = privateTicketService;
export const { commentTicketId } = privateTicketService;
export const { commentId } = privateTicketService;
export const { masiveCommentsById } = privateTicketService;
export const { createCommentMasiveTicket } = privateTicketService;
export const { cancelTicket } = privateTicketService;
export const { finishTicketBaja } = privateTicketService;
export const { TicketTableQuery } = privateTicketService;
export const { TicketTableResume } = privateTicketService;
export const { TicketTableQueryCases } = privateTicketService;
export const { TicketTableQueryGroups } = privateTicketService;
export const { TicketResendFormIncorporation } = privateTicketService;
export const { TicketqueryParamsBaja } = privateTicketService;
export const { TicketqueryParamsAlta } = privateTicketService;
export const { TicketqueryParamsModificacion } = privateTicketService;
export const { getModificacionMasivaTickets } = privateTicketService;
export const { postTicketBajaIndividual } = privateTicketService;
export const { ticketBajaMasiva } = privateTicketService;
export const { dataTicket } = ticketServicePublic;
export const { apiTicket } = privateTicketService;
export const { getTicketId } = privateTicketService;
export const { editTicketDetails } = privateTicketService;
export const { employeTicket } = privateTicketService;
export const { sendInformationToInsuranceCompany } = privateTicketService;
export const { insuranceCompanyUploadFormType } = privateTicketService;
export const { updateInsuranceCompanyUploadFormType } = privateTicketService;
export const { deleteInsuranceCompanyUploadFormType } = privateTicketService;
export const { employeeStockSearch } = privateTicketService;
export const { caseTicketId } = privateTicketService;
export const { caseAntecedentes } = privateTicketService;
export const { caseAntecedentesVerify } = ticketServicePublic;
export const { caseRechazo } = privateTicketService;
export const { caseId } = privateTicketService;
export const { typeCases } = privateTicketService;
export const { fileByCase } = privateTicketService;
export const { fileTicketRecord } = privateTicketService;
export const { caseState } = privateTicketService;
export const { getFile } = privateTicketService;
export const { fileInsuranceCompany } = privateTicketService;
export const { filesByTicket } = privateTicketService;
export const { reapplyFile } = privateTicketService;
export const { getFileUrl } = privateTicketService;
export const { dashboard } = privateTicketService;
export const { dashboardGraphicCompanyType } = privateTicketService;
export const { dataMovmentCompany } = privateTicketService;
export const { companyTickets } = privateTicketService;
export const { createAltaIndividualTicketToCompany } = privateTicketService;
export const { ticketIncome } = privateTicketService;
export const { getTransferInformation } = privateTicketService;
export const { getTransferGroups } = privateTicketService;
export const { createComment } = privateTicketService;
export const { createEmployeeDependentsIncorporation } = privateTicketService;
export const { createInsuranceDependentsIncorporation } = privateTicketService;
export const { cancelTicketAlta } = privateTicketService;
export const { cancelTicketBaja } = privateTicketService;
export const { cancelTicketModificacionMasiva } = privateTicketService;
export const { cancelTicketModificacionIndividual } = privateTicketService;
export const { ejecutarTicketMOficacionMasiva } = privateTicketService;
export const { ejecutarTicketModificacion } = privateTicketService;
export const { verifyDocumentRequestToken } = ticketServicePublic;
export const { uploadDocumentRequested } = privateTicketService;
export const { getOldDocument } = privateTicketService;
export const { getBlankDocument } = privateTicketService;
export const { createStateTicket } = privateTicketService;
export const { createCaseId } = privateTicketService;
export const { securedFileUpload } = privateTicketService;
export const { caseFilesUpload } = privateTicketService;
export const { uploadFile } = privateTicketService;
export const { uploadFileMultiple } = privateTicketService;
export const { postTicketModification } = privateTicketService;
export const { ticketModificacionMasiva } = privateTicketService;
export const { saveConsentDate } = privateTicketService;
export const { getInsuranceDocuments } = privateTicketService;
export const { updateInsuredState } = privateTicketService;
export const { createTransferTicket } = privateTicketService;
export const { getInsuranceCompaniesByEmployeeRut } = privateTicketService;
export const { executeTransferTicket } = privateTicketService;
export const { cancelTransferTicket } = privateTicketService;
export const { getCaseData } = privateTicketService;
export const { getAppealEmails } = privateTicketService;
export const { postRejectionAppeal } = privateTicketService;
export const { aprroveOrDenyAppeal } = privateTicketService;
export const { getCaseFiles } = privateTicketService;
export const { getOutOfTimeCases } = privateTicketService;
export const { getRechazoCases } = privateTicketService;
export const { getAntecedentesCases } = privateTicketService;
export const { createAltaIndividualTicketToCollaborator } =
  privateTicketService;
export const { finishAltaIndividual } = privateTicketService;
export const { getAltaMasivaTickets } = privateTicketService;
export const { getBajaMasivaTickets } = privateTicketService;
export const { postAltaMasivaFile } = privateTicketService;
export const { postAltaMasivaForm } = privateTicketService;
export const { getAltaMasivaDetail } = privateTicketService;
export const { getBajaMasivaForm } = privateTicketService;
export const { getAltaMasivaFiles } = privateTicketService;
export const { precancelAltaMasiva } = privateTicketService;
export const { presuccessAltaMasiva } = privateTicketService;
export const { cancelAltaMasiva } = privateTicketService;
export const { cancelBajaMasiva } = privateTicketService;
export const { successBajaMasiva } = privateTicketService;
export const { successAltaMasiva } = privateTicketService;
export const { getAltaMasivaForm } = privateTicketService;
export const { getMassiveIncorporationToEmployeeFormat } = privateTicketService;
export const { getUploadStockTemplate } = privateTicketService;
export const { covergarePolicy } = privateTicketService;
export const { apiInsurance } = insuranceService;
export const { massiveRegistrationInitialLoad } = insuranceService;
export const { getInsurances } = insuranceService;
export const { getAnInsurance } = insuranceService;
export const { postInsurance } = insuranceService;
export const { putInsurance } = insuranceService;
export const { deleteInsurance } = insuranceService;
export const { getInsuranceCompanyExecutive } = insuranceService;
export const { postInsuranceCompanyExecutive } = insuranceService;
export const { putInsuranceCompanyExecutive } = insuranceService;
export const { deleteInsuranceCompanyExecutive } = insuranceService;
export const { getCompanyList } = privateTicketService;
export const { getGroupCompanyList } = privateTicketService;
export const { getinsuranceCompanyList } = privateTicketService;
export const { getinsuranceCompanyPolicyList } = privateTicketService;
export const { getinsuranceCompanyDocumentsList } = privateTicketService;
export const { getEmployeeDataByRut } = privateTicketService;
export const { createCollectionForm } = allCollectionTicket;
export const { createCollectionFormPayroll } = allCollectionTicket;
export const { getCollectionListByCollectionGroup } = allCollectionTicket;
export const { collectionFormPayrollByInsuranceCompany } = allCollectionTicket;
export const { payrollObservations } = allCollectionTicket;
export const { collectionStockDownload } = allCollectionTicket;
export const { collectionFormDownload } = allCollectionTicket;
export const { payrollReprocessing } = allCollectionTicket;
export const { deletePayroll } = allCollectionTicket;
export const { collectionResourceObservations } = allCollectionTicket;
export const { collectionResourceDelete } = allCollectionTicket;
export const { collectionResourceReprocess } = allCollectionTicket;
export const { collectionResourceAttachOriginalPayroll } = allCollectionTicket;
export const { getFileCollectionTicket } = allCollectionTicket;
export const { addCollectionTicketPayroll } = allCollectionTicket;
export const { confirmCollectionReopen } = allCollectionTicket;
export const { getCollectionTicketPayrolls } = allCollectionTicket;
export const { getCollectionReprocessRecords } = allCollectionTicket;
export const { collectionForms } = allCollectionTicket;
export const { collectionTickets } = allCollectionTicket;
export const { collectionInsuranceCompanyInvoiceUploadValidation } =
  allCollectionTicket;
export const { collectionFormById } = allCollectionTicket;
export const { updateStatecollectionForm } = allCollectionTicket;
export const { collectionFormInsuranceCompanies } = allCollectionTicket;
export const { collectionFormInsuranceCompaniesPolicies } = allCollectionTicket;
export const { collectionFormInsuranceCompaniesInvoices } = allCollectionTicket;
export const { collectionFormSummary } = allCollectionTicket;
export const { collectionCompaniesSummary } = allCollectionTicket;
export const { allCollectionFormSummary } = allCollectionTicket;
export const { allCollectionSummary } = allCollectionTicket;
export const { createCollectionFormInsuranceCompaniesInvoices } =
  allCollectionTicket;
export const { invoiceById } = allCollectionTicket;
export const { patchMarkInvoicePaid } = allCollectionTicket;
export const { editInvoice } = allCollectionTicket;
export const { deleteInvoice } = allCollectionTicket;
export const { reprocessInvoice } = allCollectionTicket;
export const { invoiceObservations } = allCollectionTicket;
export const { invoiceSummary } = allCollectionTicket;
export const { invoiceDefaultersSummary } = allCollectionTicket;
export const { invoiceDefaultersSummaryList } = allCollectionTicket;
export const { getCollectionGroupComments } = allCollectionTicket;
export const { postCollectionGroupComments } = allCollectionTicket;
export const { invoicePoliciesById } = allCollectionTicket;
export const { companiesByInvoiceId } = allCollectionTicket;
export const { createReprocess } = allCollectionTicket;
export const { getAllReprocess } = allCollectionTicket;
export const { getReprocessByCollectionId } = allCollectionTicket;
export const { getRequestsByCollectionId } = allCollectionTicket;
export const { getRequestsIdCollection } = allCollectionTicket;
export const { postRequestByCollectionId } = allCollectionTicket;
export const { getRequestsById } = allCollectionTicket;
export const { getAllRequests } = allCollectionTicket;
export const { getAttachmentsByCollectionId } = allCollectionTicket;
export const { aproveCollectionTicket } = allCollectionTicket;
export const { getReprocessById } = allCollectionTicket;
export const { changeReprocessState } = allCollectionTicket;
export const { changeRequestState } = allCollectionTicket;
export const { getProcessByReprocessId } = allCollectionTicket;
export const { getReprocessesComments } = allCollectionTicket;
export const { createReprocessesComment } = allCollectionTicket;
export const { getReprocessSummary } = allCollectionTicket;
export const { selectInvoicePoliciesById } = allCollectionTicket;
export const { getCollectionComments } = allCollectionTicket;
export const { postCollectionComments } = allCollectionTicket;
export const { getBillingDetailList } = allCollectionTicket;
export const { getCollections } = allCollectionTicket;
export const { getDataSendToRRHH } = allCollectionTicket;
export const { postDataSendRRHH } = allCollectionTicket;
export const { createRequestsComment } = allCollectionTicket;
export const { getRequestsComments } = allCollectionTicket;
export const { getRequestsSummary } = allCollectionTicket;
export const { getAllFileInvoice } = allCollectionTicket;
export const { postFileInvoice } = allCollectionTicket;
export const { patchFileInvoice } = allCollectionTicket;
export const { deleteFileInvoice } = allCollectionTicket;
export const { patchOriginalPayroll } = allCollectionTicket;
export const { getFileOriginalPayroll } = allCollectionTicket;
export const { postValidatePayroll } = allCollectionTicket;
export const { getCollectionInconsistencies } = allCollectionTicket;
export const { downloadFileInconsistencies } = allCollectionTicket;
export const { squaringCollection } = allCollectionTicket;
export const { getInvoicesHumanSend } = allCollectionTicket;
export const { postSendPrecollectionInvoices } = allCollectionTicket;
export const { postCollectionTicketHumanSend } = allCollectionTicket;
export const { getRetroactiveAdjustmentByCollection } = allCollectionTicket;
export const { getDigitalForm } = digitalFormService;
export const { getBeneficiares } = digitalFormService;
export const { putBeneficiares } = digitalFormService;
export const { getDependants } = digitalFormService;
export const { putDependants } = digitalFormService;
export const { getInsureds } = digitalFormService;
export const { putInsureds } = digitalFormService;
export const { getDiseases } = digitalFormService;
export const { getInsuredsWithDiseases } = digitalFormService;
export const { getBanks } = digitalFormService;
export const { getSports } = digitalFormService;
export const { putSports } = digitalFormService;
export const { getInsuredWithSports } = digitalFormService;
export const { getPrevisualizationFile } = digitalFormService;
export const { getDigitalFormCompany } = digitalFormService;
export const { getDigitalFormPolicies } = digitalFormService;
export const { saveDigitalFormPolicies } = digitalFormService;
export const { getDigitalFormMaritalStatus } = digitalFormService;
export const { getPrevisionalHealthSystem } = digitalFormService;
export const { getMunicipalities } = digitalFormService;
export const { putDigitalFormEmployee } = digitalFormService;
export const { getDigitalFormEmployee } = digitalFormService;
export const { putPaymentMethod } = digitalFormService;
export const { getPaymentMethod } = digitalFormService;
export const { putObverse } = digitalFormService;
export const { getObverse } = digitalFormService;
export const { putReverse } = digitalFormService;
export const { getReverse } = digitalFormService;
export const { getPets } = digitalFormService;
export const { getDeclaredPets } = digitalFormService;
export const { putDeclaredPets } = digitalFormService;
export const { putConsent } = digitalFormService;
export const { getConsent } = digitalFormService;
export const { getDigitalFormResetedToken } = digitalFormService;
export const { getCodeToSign } = digitalFormService;
export const { signDocument } = digitalFormService;
export const { firmState } = digitalFormService;
export const { digitalFormLogo } = digitalFormService;
export const { getDependentsIncorporationForm } = privateTicketService;
export const { getDependentsOfDependentsIncorporationForm } =
  privateTicketService;
export const { putDependentsOfDependentsIncorporationForm } =
  privateTicketService;
export const { putDocumentOfDependentsIncorporationForm } =
  privateTicketService;
export const { resendDependentsIncorporationForm } = privateTicketService;
export const { sendToBrokerDependentsIncorporationForm } = privateTicketService;
export const { getDependentsIncorporationsOfTicket } = privateTicketService;
export const { getDependentsExclusionsOfTicket } = privateTicketService;
export const { cancelDependentsIncorporation } = privateTicketService;
export const { cancelDependentsExclusion } = privateTicketService;
export const { sendToInsuranceDependentsIncorporation } = privateTicketService;
export const { finishDependentsIncorporation } = privateTicketService;
export const { finishDependentsExclusion } = privateTicketService;
export const { createDependentsExclusion } = privateTicketService;
export const { getPoliciesForDependentsIncorporation } = privateTicketService;
export const { getCompaniesForDependentsIncorporation } = privateTicketService;
export const { updateEndDateBeforeFinishExclusion } = privateTicketService;
export const { updateEndDateBeforeFinishMassiveExclusion } =
  privateTicketService;
export const { getDependents } = companyClient;
