<template>
  <page-header title="Formulario de modificación masiva" :breadcrumRoutes="routes" />
  <a-form name="form" layout="vertical" ref="formRef" :model="formState" :rules="rules" @finish="onSubmit">
    <a-card class="mt-2">
      <a-row class="mb-2">
        <a-col>
          <a-typography-title class="text-gray-8" :level="4">
            Datos ticket
          </a-typography-title>
        </a-col>
      </a-row>
      <a-row :gutter="16" type="flex">
        <a-col :span="8">
          <a-form-item label="Empresa" name="company" has-feedback>
            <a-select placeholder="Selecciona una empresa" v-model:value="formState.company" :options="dataCompany"
              @change="loadInsurance">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="Aseguradora" has-feedback name="insuranceCompany">
            <a-select v-model:value="formState.insuranceCompany" placeholder="Selecciona una aseguradora"
              :options="insurancesData" :disabled="blockInsurance">
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-card>
    <a-row :gutter="16" type="flex" class="mt-2">
      <a-col :span="13">
        <a-card style="height: 340px">
          <a-row class="mb-2">
            <a-col>
              <a-typography-title class="text-gray-8" :level="4">
                Mensaje a compañía aseguradora
              </a-typography-title>
            </a-col>
          </a-row>
          <a-form-item has-feedback label="Escribe mensaje para compañía aseguradora" name="message">
            <a-textarea v-model:value="formState.message" placeholder="Escribe aquí..." :rows="6" />
          </a-form-item>
        </a-card>
      </a-col>
      <a-col :span="11">
        <a-card style="height: 340px">
          <a-typography-title class="text-gray-8" :level="4">
            Adjuntar documentos
          </a-typography-title>
          <a-form-item label="Adjunta los documentos para la compañía aseguradora" has-feedback name="fileList">
            <a-row align="middle" class="mt-2 mb-4">
              <a-upload-dragger style="max-width: 220px" :file-list="formState.fileList" name="fileList" multiple
                @change="handleChange" :before-upload="beforeUpload">
                <p class="ant-upload-drag-icon">
                  <UploadOutlined />
                </p>

                <div class="ant-upload-text fs-6 text-gray-9">
                  Haz click o arrastra aquí el archivo para cargarlo
                </div>
              </a-upload-dragger>
            </a-row>
          </a-form-item>
        </a-card>
      </a-col>
    </a-row>
    <a-form-item class="mt-4">
      <a-row type="flex" :class="buttonStyle()">
        <a-col>
          <a-button size="large" class="px-4" type="primary" block @click="formSubmit" :disabled="false">Siguiente
          </a-button>
        </a-col>
      </a-row>
    </a-form-item>

    <a-modal v-model:visible="visible" title="Resumen modificacion masiva" @ok="handleOk" :closable="!loading"
      :maskClosable="false">
      <template #footer>
        <a-row type="flex" justify="end">
          <a-col>
            <a-space>
              <a-button @click="visible = false" :disabled="loading">Cancelar</a-button>
              <a-button class="bg-blue-6" key="submit" type="primary" @click="onSubmit"
                :loading="loading">Enviar</a-button>
            </a-space>
          </a-col>
        </a-row>
      </template>
      <a-typography-title class="text-gray-8" :level="4">
        Datos corporativos
      </a-typography-title>
      <a-typography-text>
        Revisa que los datos ingresados esten correctos.
      </a-typography-text>

      <a-descriptions class="mb-4 mt-2" bordered>
        <a-descriptions-item label="Empresa" :span="3">
          {{
            dataCompany
              .map((e) => e)
              .filter((f) => f.value == formState.company)
              .map((e) => e.label)[0]
          }}
        </a-descriptions-item>
        <a-descriptions-item label="Aseguradora" :span="3">
          {{
            insurancesData
              .map((e) => e)
              .filter((f) => f.value == formState.insuranceCompany)
              .map((e) => e.label)[0]
          }}
        </a-descriptions-item>
        <a-descriptions-item label="Documento adjuntos" :span="3">
          <ul style="margin: 0; padding: 0 0 0 8px">
            <li v-for="(file, index) of formState.fileList" :key="index">
              {{ file.name }}
            </li>
          </ul>
        </a-descriptions-item>
      </a-descriptions>
      <a-typography-title class="text-gray-8" :level="4">
        Mensaje a compañía aseguradora
      </a-typography-title>
      <a-collapse>
        <a-collapse-panel key="1" header="Desplegar mensaje a compañía aseguradora">
          <p v-html="formState.message
              ? formState.message.replace(/(\r\n|\n|\r)/gm, '<br>')
              : 'No hay mensaje'
            "></p>
        </a-collapse-panel>
      </a-collapse>
    </a-modal>
  </a-form>

  <template v-if="showSucess">
    <a-modal v-model:visible="showSucess" centered footer="" :maskClosable="false">
      <a-result status="success" title="Ticket creado"
        sub-title="Tu ticket de modificación masiva ha sido creado con éxito.">
        <template #extra>
          <router-link class="justify-content-center d-flex" :to="{
            name: 'DetalleModificacionMasiva',
            params: { id: createdTicketId }
          }">
            <a-button key="console" type="primary">Ver ticket</a-button>
          </router-link>
        </template>
      </a-result>
    </a-modal>
  </template>
</template>

<script>
import { reactive, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
import { UploadOutlined } from '@ant-design/icons-vue';
// Store
import {
  FETCH_AM_INSURANCES,
  GET_BROKER_ID,
  FETCH_ALL_COMPANIES,
  POST_MODIFICACION_MASIVA
} from '@/store/types';
// Components
import PageHeader from '@/components/PageHeader.vue';
// Utils
import openNotificationWithIcon from '@/utils/notifications';

const routes = [
  {
    path: '/modificaciones',
    breadcrumbName: 'Modificaciones'
  },
  {
    breadcrumbName: 'Masiva'
  }
];

export default {
  name: 'ModificacionMasiva',
  components: { 'page-header': PageHeader, UploadOutlined },
  setup() {
    const store = useStore();
    //* Get Data
    const dataCompany = ref([]);
    const insurancesData = ref([]);
    const blockInsurance = ref(true);
    const documentURL = ref(null);
    const documentsData = ref([]);
    const blockInsuranceSelect = () => {
      formState.insuranceCompany = '';
      insurancesData.value.length = 0;
      blockInsurance.value = true;
    };
    watchEffect(async () => {
      await store
        .dispatch(FETCH_ALL_COMPANIES)
        .then((response) => {
          dataCompany.value = response.data.map((a) => {
            return {
              value: a.id,
              label: a.businessName
            };
          });
          if (insurancesData.value > 0) {
            blockInsuranceSelect();
          }
        })
        .catch((err) => {
          blockInsurance.value = true;
          console.log(err.response);
          message.error('compañia no existe');
        });
    });
    const loadInsurance = async () => {
      try {
        const response = await store.dispatch(
          FETCH_AM_INSURANCES,
          formState.company
        );
        insurancesData.value = response.data.map((a) => {
          return {
            value: a.id,
            label: a.businessName
          };
        });
        blockInsurance.value = false;
      } catch (error) {
        blockInsurance.value = true;
        // blockInsurance.value = true;
        console.log(error);
      }
    };
    const formSubmit = () => {
      formRef.value.validate().then(() => {
        visible.value = true;
      });
    };

    // TODO: VER VARIABLES SIN USO
    // eslint-disable-next-line no-unused-vars
    const handleOk = (e) => {
      console.log(e);
      visible.value = false;
    };
    //* Form
    const formRef = ref();
    const formState = reactive({
      company: null,
      brokerId: store.getters[GET_BROKER_ID],
      insuranceCompany: null,
      docs: [],
      message:
        'A través del presente correo solicitamos la siguiente modificación masiva para los rut de colaboradores en el documento adjunto.',
      fileList: []
    });
    const loading = ref(false);
    const buttonStyle = () => {
      let style = 'justify-content-end';
      if (formState.typeForm === '2') {
        style = 'justify-content-start';
      }
      return style;
    };

    const handleChange = ({ file }) => {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 5) {
        message.error('El archivo excede el peso máximo 5MB');
        return;
      }
      if (formState.fileList.length >= 5) {
        message.error('Solo puedes subir 5 archivos');
        return;
      }
      formState.fileList.push(file);
    };

    const beforeUpload = () => {
      return false;
    };

    //* Modal
    const visible = ref(false);
    const showSucess = ref(false);
    const createdTicketId = ref(null);
    const modalStyle = reactive({
      title: '',
      text: '',
      cancelText: '',
      confirmText: '',
      successText: ''
    });

    //* Validations
    const rules = {
      company: [
        {
          required: true,
          message: 'Seleccione una empresa',
          trigger: 'change',
          type: 'number'
        }
      ],
      insuranceCompany: [
        {
          required: true,
          message: 'Seleccione una compañía aseguradora',
          trigger: 'change',
          type: 'number'
        }
      ],
      docs: [
        {
          type: 'array',
          required: true,
          message: 'Seleccione al menos un formulario',
          trigger: 'change'
        }
      ],
      message: [
        {
          required: true,
          message: 'Ingrese un mensaje',
          trigger: 'change',
          type: 'string'
        }
      ],
      fileList: [
        {
          type: 'array',
          required: true,
          message: 'Adjunte un archivo',
          trigger: 'change'
        }
      ]
    };

    //* Actions
    const onSubmit = async () => {
      loading.value = true;
      try {
        await store
          .dispatch(POST_MODIFICACION_MASIVA, formState)
          .then((res) => {
            createdTicketId.value = res.id;
            loading.value = false;
            openNotificationWithIcon(
              'success',
              'Correcto',
              'Ticket de modificación masiva creado con éxito'
            );
            visible.value = false;
            showSucess.value = true;
            formRef.value.resetFields();
          });
      } catch (error) {
        loading.value = false;
        openNotificationWithIcon(
          'error',
          'Error',
          `Error al generar el ticket de modificación masiva. ${error.response.data.message}`
        );
      }
    };

    return {
      //* Variables
      routes,
      loadInsurance,
      blockInsuranceSelect,
      blockInsurance,
      dataCompany,
      insurancesData,
      documentsData,
      documentURL,
      modalStyle,
      visible,
      formRef,
      formState,
      rules,
      loading,
      showSucess,
      createdTicketId,
      //* Methods
      handleChange,
      beforeUpload,
      buttonStyle,
      handleOk,
      formSubmit,
      onSubmit
    };
  }
};
</script>
