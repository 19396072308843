import {
  Company,
  companyInsuranceCompany,
  fileInsuranceCompany,
  getAltaMasivaTickets,
  postAltaMasivaFile,
  postAltaMasivaForm,
  getAltaMasivaForm,
  getMassiveIncorporationToEmployeeFormat,
  getAltaMasivaDetail,
  getAltaMasivaFiles,
  precancelAltaMasiva,
  cancelAltaMasiva,
  presuccessAltaMasiva,
  successAltaMasiva,
  TicketqueryParamsAlta,
  resendEmail,
  getBajaStockFormat,
  uploadExcelToBajaStock
} from '../../../../network';
import {
  GET_AM_COMPANIES,
  GET_AM_INSURANCES,
  GET_AM_DOCS,
  GET_AM_FORM,
  GET_AM_URL,
  GET_AM_TICKETS,
  GET_AM_DETAIL,
  GET_AM_DETAIL_FILE,
  SET_AM_COMPANIES,
  SET_AM_INSURANCES,
  SET_AM_DOCS,
  SET_AM_FORM,
  SET_AM_URL,
  SET_AM_TICKETS,
  SET_AM_DETAIL,
  SET_AM_DETAIL_FILE,
  FETCH_AM_COMPANIES,
  FETCH_AM_INSURANCES,
  FETCH_AM_DOCS,
  FETCH_AM_FORM,
  FETCH_AM_URL,
  URL_MASSIVE_INCORPORATION_TO_EMPLOYEE_TEMPLATE,
  FETCH_AM_TICKETS,
  FETCH_AM_DETAIL,
  FETCH_AM_DETAIL_FILE,
  POST_ALTA_MASIVA,
  PRECANCEL_ALTA_MASIVA,
  CANCEL_ALTA_MASIVA,
  PRESUCCESS_ALTA_MASIVA,
  SUCCESS_ALTA_MASIVA,
  SET_AI_TICKETS,
  FETCH_AI_TICKETS,
  GET_AI_TICKETS,
  POST_RESEEND_MAIL,
  FETCH_BAJA_STOCK_FORMAT,
  POST_EXCEL_BAJA_STOCK
} from './types';

const store = {};

const getDefaultState = () => ({
  tickets: [],
  altaIndividual: [],
  companies: [],
  insurances: [],
  docs: [],
  form: {},
  url: '',
  ticketDetail: {},
  ticketDetailFile: {}
});

store.state = getDefaultState();

store.getters = {
  [GET_AI_TICKETS]: (state) => state.altaIndividual,
  [GET_AM_TICKETS]: (state) => state.tickets,
  [GET_AM_COMPANIES]: (state) => state.companies,
  [GET_AM_INSURANCES]: (state) => state.insurances,
  [GET_AM_DOCS]: (state) => state.docs,
  [GET_AM_FORM]: (state) => state.form,
  [GET_AM_URL]: (state) => state.url,
  [GET_AM_DETAIL]: (state) => state.ticketDetail,
  [GET_AM_DETAIL_FILE]: (state) => state.ticketDetailFile
};

store.mutations = {
  [SET_AI_TICKETS](state, payload) {
    state.altaIndividual = payload
      .map((a) => {
        const dataCount = { total: 0, executed: 0, cancelled: 0, progress: 0 };
        const estadoTicket = a.states[a.states.length - 1].state.id;
        if (estadoTicket === 3) {
          dataCount.executed++;
        } else if (estadoTicket === 4) {
          dataCount.cancelled++;
        } else {
          dataCount.progress++;
        }

        return {
          id: a.id,
          key: a.id,
          holding: a.company?.holding
            ? a.company?.holding.businessName
            : a.company.subHolding?.holding.businessName,
          subholding: a
            ? a.company.subHolding
              ? a.company.subHolding.name
              : ''
            : '',
          companyName: a.company.businessName,
          companyRut: a.company.rut,
          employeeName: `${a.employee.firstName} ${a.employee.firstSurname}`,
          email: a?.employee?.email,
          employeeRut: a.employee.rut,
          ticketState: a.states.sort((a, b) => {
            return b.id - a.id;
          })[0].state.id,
          createdAt: a.createdAt,
          detail: '',
          isRequesting: a.isRequesting
        };
      })
      .map((f) => {
        if (f.isRequesting == true) {
          f.ticketState = 9;
        }
        return f;
      });
  },
  [SET_AM_TICKETS](state, payload) {
    state.tickets = payload.map((a) => {
      return {
        id: a.id,
        key: a.id,
        holding: a.holding ? a.holding : '',
        subholding: a.subHolding ? a.subHolding : '',
        companyName: a.businessName,
        companyRut: a.companyRut,
        count: a.count,
        createdAt: a.createdAt,
        detail: 'Ver más'
      };
    });
  },
  [SET_AM_COMPANIES](state, payload) {
    state.companies = payload.map((a) => {
      return {
        value: a.id,
        label: a.businessName
      };
    });
  },
  [SET_AM_INSURANCES](state, payload) {
    state.insurances = payload.map((a) => {
      return {
        value: a.id,
        label: a.businessName,
        formType: a.insuranceCompanyForm?.type ?? 'PDF_FORM'
      };
    });
  },
  [SET_AM_DOCS](state, payload) {
    state.docs = payload.map((a) => {
      return {
        value: a.id,
        label: a.name
      };
    });
  },
  [SET_AM_FORM](state, payload) {
    state.form = payload;
  },
  [SET_AM_URL](state, payload) {
    state.url = payload;
  },
  [SET_AM_DETAIL](state, payload) {
    state.ticketDetail = payload;
  },
  [SET_AM_DETAIL_FILE](state, payload) {
    state.ticketDetailFile = payload.map((a) => {
      return {
        id: a.id,
        key: a.id,
        name: a.name,
        route: a.presignedFile,
        ver: 'Ver más'
      };
    });
  }
};

store.actions = {
  async [FETCH_AI_TICKETS]() {
    return new Promise((resolve, reject) => {
      TicketqueryParamsAlta().then(
        (resp) => {
          // commit(SET_AI_TICKETS,resp.data);
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  async [FETCH_AM_TICKETS]({ commit }) {
    let error = '';
    const response = await getAltaMasivaTickets().catch((err) => (error = err));
    if (response && response.status === 200) {
      commit(SET_AM_TICKETS, response.data);
    } else {
      throw error;
    }
  },
  async [FETCH_AM_COMPANIES]({ commit }) {
    let error = '';
    const response = await Company().catch((err) => (error = err));
    if (response && response.status === 200) {
      commit(SET_AM_COMPANIES, response.data);
    } else {
      throw error;
    }
  },
  async [FETCH_AM_INSURANCES]({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      companyInsuranceCompany(companyId).then(
        (resp) => {
          commit(SET_AM_INSURANCES, resp.data);
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  async [FETCH_AM_DOCS]({ commit }, insuranceId) {
    let error = '';
    const response = await fileInsuranceCompany(insuranceId).catch(
      (err) => (error = err)
    );
    if (response && response.status === 200) {
      commit(SET_AM_DOCS, response.data);
    } else {
      throw error;
    }
  },
  async [FETCH_AM_FORM]({ commit }) {
    let error = '';
    const response = await Company().catch((err) => (error = err));
    if (response && response.status === 200) {
      commit(SET_AM_FORM, response.data);
    } else {
      throw error;
    }
  },
  async [FETCH_AM_URL]({ commit }, payload) {
    const response = await getAltaMasivaForm(
      payload.companyId,
      payload.insuranceCompanyId
    );
    console.log(SET_AM_URL, response);
    commit(SET_AM_URL, response);
  },
  async [URL_MASSIVE_INCORPORATION_TO_EMPLOYEE_TEMPLATE](_, payload) {
    const url = await getMassiveIncorporationToEmployeeFormat(
      payload.companyId,
      payload.insuranceCompanyId
    );
    return url;
  },
  async [POST_ALTA_MASIVA](_, payload) {
    let response = '';
    let error = '';
    const formData = new FormData();
    formData.append('brokerId', payload.brokerId);
    formData.append('companyId', payload.company);
    formData.append('insuranceCompanyId', payload.insuranceCompany);
    formData.append('file', payload.fileList[0]);
    if (payload.typeForm === '1') {
      formData.append('message', payload.message);
      response = await postAltaMasivaFile(formData).catch(
        (err) => (error = err)
      );
    } else {
      payload.docs.forEach((files) => {
        formData.append('filesInsurance[]', files);
      });

      payload.optional_files.forEach((files) => {
        formData.append('optionalFiles', files);
      });

      formData.append('message', payload.messageColaborador);

      response = await postAltaMasivaForm(formData).catch(
        (err) => (error = err)
      );
    }
    if (response && (response.status === 201 || response.status === 200)) {
      return response.data;
    }
    throw error;
  },
  async [POST_RESEEND_MAIL](_, { ticketId, email }) {
    const response = await resendEmail(ticketId, email).catch(
      (e) => e.response
    );
    if ((response && response.status === 200) || response.status === 201) {
      return response.data;
    }
    throw { mensaje: 'error en la solicitud.' };
  },
  async [FETCH_AM_DETAIL]({ commit }, ticketId) {
    return new Promise((resolve, reject) => {
      getAltaMasivaDetail(ticketId).then(
        (resp) => {
          commit(SET_AM_DETAIL, resp.data);
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  async [FETCH_AM_DETAIL_FILE]({ commit }, ticketId) {
    let error = '';
    const response = await getAltaMasivaFiles(ticketId).catch(
      (err) => (error = err)
    );
    if (response && response.status === 200) {
      commit(SET_AM_DETAIL_FILE, response.data);
    } else {
      throw error;
    }
  },
  async [PRECANCEL_ALTA_MASIVA](_, payload) {
    let error = '';
    const response = await precancelAltaMasiva(payload).catch(
      (err) => (error = err)
    );
    if (response && (response.status === 201 || response.status === 200)) {
      return response.data;
    }
    throw error;
  },
  async [CANCEL_ALTA_MASIVA](_, { idAlta, payload }) {
    let error = '';
    const response = await cancelAltaMasiva(idAlta, payload).catch(
      (err) => (error = err)
    );
    if (response && (response.status === 201 || response.status === 200)) {
      return response.data;
    }
    throw error;
  },
  async [PRESUCCESS_ALTA_MASIVA](_, payload) {
    let error = '';
    const response = await presuccessAltaMasiva(payload).catch(
      (err) => (error = err)
    );
    if (response && (response.status === 201 || response.status === 200)) {
      return response.data;
    }
    throw error;
  },
  async [SUCCESS_ALTA_MASIVA](_, { idAlta, payload }) {
    let error = '';
    const response = await successAltaMasiva(idAlta, payload).catch(
      (err) => (error = err)
    );
    if (response && (response.status === 201 || response.status === 200)) {
      return response.data;
    }
    throw error;
  },
  [FETCH_BAJA_STOCK_FORMAT](_, { companyId, insuranceCompanyId }) {
    return new Promise((resolve, reject) => {
      try {
        const resp = getBajaStockFormat(companyId, insuranceCompanyId);
        resolve(resp);
      } catch (error) {
        reject(error);
      }
    });
  },
  [POST_EXCEL_BAJA_STOCK](_, { companyId, payload }) {
    return new Promise((resolve, reject) => {
      uploadExcelToBajaStock(companyId, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export default store;
